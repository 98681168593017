import { Coords } from './internal-interfaces';
import {
  IsoCodeRecipe,
  PlacemarkRecipe,
  PureRecipe,
  UrlRecipe,
} from './url-recipe-types';

interface BasePageDescription {
  recipe: UrlRecipe;
}

export interface PlacemarkPageDescription extends BasePageDescription {
  recipe: PlacemarkRecipe;
  geoObjectKey: string;
  urlPath: string;
}

export interface IsoCodePageDescription extends BasePageDescription {
  recipe: IsoCodeRecipe;
  isoCode?: string;
}

export const radarPeriodKeys = [
  'periodCurrent',
  'periodPrognose24h',
  'periodTomorrow',
] as const;
export type RadarPeriod = (typeof radarPeriodKeys)[number];

export const shortRadarLayers = ['wr', 'rr', 'tr', 'gr', 'lr'] as const;
export const longRadarLayers = [
  'WetterRadar',
  'RegenRadar',
  'Temperature',
  'Gust',
  'Lightning',
] as const;
export const radarLayers = [...shortRadarLayers, ...longRadarLayers] as const;
export type RadarLayer = (typeof radarLayers)[number];
export type ShortRadarLayer = (typeof shortRadarLayers)[number];

export interface RadarPageDescription extends BasePageDescription {
  recipe: 'radar';
  geoObjectKey?: string;
  urlPath?: string;
  layer?: RadarLayer;
  centerCoords?: Coords;
  placemarkCoords?: Coords;
  tz?: string;
  zoom?: number;
  bounds?: string;
  period?: RadarPeriod;
  loop?: 'true';
  timeStep?: string;
}

export interface ArticlePageDescription extends BasePageDescription {
  recipe: 'article' | 'productMarketingArticle';
  postId: string;
}

export interface TeaserPageDescription extends BasePageDescription {
  recipe: 'teaser';
  page?: string;
  category?: string;
}

export interface ProductMarketingRootPageDescription
  extends BasePageDescription {
  recipe: 'productMarketingRoot';
  page?: string;
}

export interface AuthorsPageDescription extends BasePageDescription {
  recipe: 'authors';
  fragment?: string;
}

/**
 * Pages that don't have any configuration
 */
export interface PurePageDescription extends BasePageDescription {
  recipe: PureRecipe;
}

export type PageDescription =
  | PlacemarkPageDescription
  | IsoCodePageDescription
  | RadarPageDescription
  | ArticlePageDescription
  | TeaserPageDescription
  | ProductMarketingRootPageDescription
  | AuthorsPageDescription
  | PurePageDescription;

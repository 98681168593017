import {
  ArticlePageDescription,
  AuthorsPageDescription,
  IsoCodePageDescription,
  PageDescription,
  PlacemarkPageDescription,
  ProductMarketingRootPageDescription,
  PurePageDescription,
  RadarPageDescription,
  TeaserPageDescription,
} from './page-descriptions';
import { Url } from './url';
import { UrlBuilder } from './url-builder';
import {
  IsoCodeRecipe,
  isoCodeRecipes,
  PlacemarkRecipe,
  placemarkRecipes,
  PureRecipe,
  pureRecipes,
  RandomRecipe,
  randomRecipes,
  UrlRecipe,
} from './url-recipe-types';

export abstract class BaseUrlBuilder implements UrlBuilder {
  abstract get hostname(): string;

  build(input: PageDescription): Url {
    if (placemarkRecipes.includes(input.recipe as PlacemarkRecipe)) {
      return this.buildPlacemarkBased(input as PlacemarkPageDescription);
    }

    if (isoCodeRecipes.includes(input.recipe as IsoCodeRecipe)) {
      return this.buildIsoCodeBased(input as IsoCodePageDescription);
    }

    if (randomRecipes.includes(input.recipe as RandomRecipe)) {
      const recipe = input.recipe as RandomRecipe;
      if (recipe === 'radar') {
        return this.buildRadar(input as RadarPageDescription);
      }
      if (recipe === 'article' || recipe === 'productMarketingArticle') {
        return this.buildArticle(input as ArticlePageDescription);
      }
      if (recipe === 'teaser') {
        return this.buildTeaser(input as TeaserPageDescription);
      }
      if (recipe === 'productMarketingRoot') {
        return this.buildProductMarketingRoot(
          input as ProductMarketingRootPageDescription,
        );
      }
      if (recipe === 'authors') {
        return this.buildAuthors(input as AuthorsPageDescription);
      }
    }

    if (pureRecipes.includes(input.recipe as PureRecipe)) {
      return this.buildPure(input as PurePageDescription);
    }

    throw new Error(
      `Unrecognized input recipe in BaseUrlBuilder: ${JSON.stringify(input)}`,
    );
  }

  abstract basePath(recipe: UrlRecipe): Url;
  abstract doesRecognize(pathSegment: string): boolean;
  abstract translate(foreignSegment: string): string | undefined;

  abstract parse(url: Url): PageDescription | null;

  protected abstract buildPlacemarkBased(input: PlacemarkPageDescription): Url;
  protected abstract buildIsoCodeBased(input: IsoCodePageDescription): Url;
  protected abstract buildRadar(input: RadarPageDescription): Url;
  protected abstract buildArticle(input: ArticlePageDescription): Url;
  protected abstract buildTeaser(input: TeaserPageDescription): Url;
  protected abstract buildProductMarketingRoot(
    input: ProductMarketingRootPageDescription,
  ): Url;
  protected abstract buildAuthors(input: AuthorsPageDescription): Url;
  protected abstract buildPure(input: PurePageDescription): Url;
}
